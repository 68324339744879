import React, {useEffect, useRef, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSearch,
    faStar as fasStar,
    faVolumeMute,
    faVolumeUp
} from '@fortawesome/free-solid-svg-icons'
import UserStoriesCSS from './UserStories.module.css'
import {
    selectStoryById,
    useUpdateStoryLikeMutation,
    useUpdateStoryUnLikeMutation
} from '../../features/story/storiesApiSlice'
import {useSelector} from 'react-redux'
import {
    selectUserById,
    useUpdateRemoveUserFollowingMutation, useUpdateUserFollowingMutation, useUpdateUserImpressionsMutation
} from '../../features/user/usersApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import Slider from 'react-slick'
import {Link, useNavigate} from 'react-router-dom'
import LoginRedirection from './LoginRedirection'
import fireIcon from '../../assets/img/fire-icon.png'
import UserFollowingCSS from '../UserProfile/UserFollowing.module.css'
import {haversineDistance, isProviderAvailable} from '../../common/utils'
import Story from './Story'
import ProfilePlaceholder from '../../assets/img/profile-placeholder.jpg'

const useStoryUrlsAndTypes = (storiesIds) => {
    return useSelector((state) => {
        const stories = storiesIds?.map((storyId) => selectStoryById(state, storyId))
        return stories?.map((story) => ({ id : story?._id, url: story?.url, storyText : story?.storyText ,likes :story?.likes, type : 'video', expired: story?.expired }))
    })
}

const UserStories = ({ follower, isActive,loggedUser,isNext, isPrevious, isMuted, setIsMuted }) => {

    const toggleMute = () => {
        setIsMuted(!isMuted);
        videoRefs.current.forEach(video => {
            if (video) {
                video.muted = !isMuted
            }
        })
    }

    //region useStates
    const sliderRef = useRef()
    const [currentSlide, setCurrentSlide] = useState(0)
    const [showLoader, setShowLoader] = useState(true)
    const videoRefs = useRef([])
    const [isFollowing ,setIsFollowing]= useState(loggedUser?.length && loggedUser[0]?.following?.includes(follower))
    const [isHolding, setIsHolding] = useState(false)
    const navigate = useNavigate()
    const [redirectOpened, setRedirectOpened] = useState(false)
    const [visited,setVisited] = useState(false)
    //endregion

    //region mutations
    const [UseUpdateRemoveUserFollowing] = useUpdateRemoveUserFollowingMutation()

    const [UseUpdateUserFollowing] = useUpdateUserFollowingMutation()

    const [UseUpdateStoryLike] = useUpdateStoryLikeMutation()

    const [UseUpdateStoryUnLike] = useUpdateStoryUnLikeMutation()
    //endregion

    const user = useSelector(state => selectUserById(state, follower))
    const currentStories = useStoryUrlsAndTypes(user?.stories)

    useEffect(() => {
        return () => {
            videoRefs.current.forEach(video => {
                if (video) {
                    video.pause();
                    video.currentTime = 0
                    video.removeAttribute('src'); // This will stop downloading the video
                }
            });
        };
    }, [currentSlide, isActive])

    useEffect(() => {
        // Set a timeout to hide the loader after 2 seconds
        if (user && currentStories){
            setShowLoader(false)
        }

        // Clean up the timer
    }, [])

    const [UseUpdateUserImpressions] = useUpdateUserImpressionsMutation()

    const handleBeforeChange = (oldIndex, newIndex) => {
        setCurrentSlide(newIndex)
    }

    const handleVideoEnd = () => {
        // Move to the next slide when the video ends
        if (sliderRef.current) {
            sliderRef.current.slickNext()

        }
    }

    useEffect(() => {
        if(isActive){
            setVisited(true)
        }
    }, [isActive]);


    useEffect(() => {
        // Increment views count when the story is viewed
        if (currentStories && currentStories[currentSlide] && isActive) {
            UseUpdateUserImpressions({ userId: follower});
        }
    }, [currentSlide,isActive]);

    const settings = {
        dots: true,
        arrows: true,
        dotsClass: 'custom-dots',
        infinite: true,
        fade:true,
        speed: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoPlay: false,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        beforeChange: handleBeforeChange,
        swipe: false
    }

    let content

    const handleStartClicked = async () => {
        if (loggedUser?.length) {
            if (isFollowing) {
                await UseUpdateRemoveUserFollowing({id: loggedUser[0]._id, followId: follower})
            } else {
                await UseUpdateUserFollowing({id: loggedUser[0]._id, followId: follower})

            }
            setIsFollowing(!isFollowing)
        }
        else{
            setRedirectOpened(true)
        }
    }
    const isLiked = () =>{
        return loggedUser?.length && currentStories && currentStories[currentSlide] && currentStories[currentSlide].likes?.includes(loggedUser[0]._id)
    }
    const handleFireClicked = async () => {
        if (loggedUser?.length && currentStories && currentStories[currentSlide]) {
            if (isLiked()) {
                await UseUpdateStoryUnLike({id: currentStories[currentSlide]?.id, userId: loggedUser[0]._id})
            } else {
                await UseUpdateStoryLike({id: currentStories[currentSlide]?.id, userId: loggedUser[0]._id})
            }
        }
        else{
            setRedirectOpened(true)
        }
    }
    const pauseCurrentVideo = () => {
        const currentVideo = videoRefs.current[currentSlide]
        if (currentVideo) {
            currentVideo.pause()
        }
    }

    const resumeCurrentVideo = () => {
        const currentVideo = videoRefs.current[currentSlide]
        if (currentVideo) {
            const playPromise = currentVideo.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    // Automatic playback started!
                    // You can handle UI changes here if needed.
                }).catch(error => {
                    // Auto-play was prevented
                    // Handle the error or show paused UI.
                    console.error('Auto-play was prevented:', error);
                });
            }
        }
    }
    const handleTouchStart = () => {
        setIsHolding(true)
        pauseCurrentVideo()
    }

    const handleTouchEnd = () => {
        setIsHolding(false)
        resumeCurrentVideo()
    }
    if(showLoader){content = <PulseLoader color={'#F92E85FF'} className='pulse-loader'/>
    } else {
        const availabilityStatus = isProviderAvailable(user?.availability);
        content = (
            <div className={UserStoriesCSS['story-container']}>
                {(isActive || isNext || isPrevious || visited) && currentStories?.length ? (
                    <Slider ref={sliderRef} {...settings}>
                        {user?.stories?.map((story, index) => (
                            <Story
                                key={index}
                                index={index}
                                videoRefs={videoRefs}
                                storyId={story}
                                isMuted={isMuted}
                                isSingle={currentStories?.length === 1}
                                sliderRef={sliderRef}
                                isActive={isActive}
                                currentSlide={currentSlide}
                                storyCount={user.stories.length}
                            />
                        ))}
                    </Slider>
                ) : <PulseLoader color={'#F92E85FF'} className='pulse-loader'/>}
                <div className={UserStoriesCSS['profile-header']}>
                    <Link key={user._id} to={`/dash/user/${user._id}`} state={{ from: '/dash/stories' }}>
                        <div className={UserStoriesCSS['profile-info']}>
                            <div className={UserStoriesCSS['profile-image-container']}>
                                <img
                                    src={user.profileImage && user.profileImage !== '' ? user.profileImage : ProfilePlaceholder} // Replace with the actual URL of the profile image
                                    alt={`${user.username}'s profile`}
                                    className={UserStoriesCSS['profile-image']}
                                />
                            </div>
                            <div className={UserStoriesCSS['profile-info-name']}>
                                <h3 className={UserStoriesCSS['profile-name']}><span className={`${user?.username.length > 15 ? UserStoriesCSS['shorted-name'] : ''}`}>{user?.username},</span><span> {user?.age}</span></h3>
                                <span className={UserStoriesCSS['profile-location']}>
                                <span className={`${UserFollowingCSS['dot']} ${availabilityStatus ? UserFollowingCSS['available'] : UserFollowingCSS['unavailable']}`}></span>
                                    {user?.location ? ` ${user.location.city}, ${haversineDistance(
                                        user.location.coordinates?.coordinates,
                                        loggedUser.length>0
                                            ? loggedUser[0]?.location?.coordinates?.coordinates
                                            : JSON.parse(localStorage.getItem('location'))?.coordinates?.coordinates
                                    )}` : "No location specified"}                                </span>
                            </div>
                        </div>
                    </Link>
                    <div className={UserStoriesCSS['heading-buttons']}>
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('/dash/search')
                            }}
                        >
                        <FontAwesomeIcon
                            icon={faSearch}
                            className={UserStoriesCSS['search-icon']}
                        />
                        Search
                        </button>
                        <FontAwesomeIcon
                            icon={isMuted ? faVolumeMute : faVolumeUp}
                            className={UserStoriesCSS['mute-icon']}
                            onClick={toggleMute}
                        />
                    </div>
                </div>
                <div className={UserStoriesCSS['reaction-icons']}>
                    <div
                        className={`${UserStoriesCSS['reaction-like']} ${isLiked() ? UserStoriesCSS['active-fire'] : UserStoriesCSS['not-active-fire']}`}
                        onClick={() => handleFireClicked()}
                    >
                        <span
                            className={UserStoriesCSS['like-count']}> {currentStories && currentStories[currentSlide] ? currentStories[currentSlide].likes?.length : null}</span>
                        <img src={fireIcon} alt="Custom Fire Icon"/>
                    </div>
                    <div
                        className={`${UserStoriesCSS['reaction-star']} ${isFollowing ? UserStoriesCSS['active-star'] : ''}`}
                    >
                    <FontAwesomeIcon onClick={() => handleStartClicked()} icon={fasStar}/>
                    </div>
                </div>
                <LoginRedirection redirectOpened={redirectOpened} closeModal={() => setRedirectOpened(false)}/>
            </div>
        )
    }

    return content
}

export default UserStories