import logo from '../../assets/img/watermark-pink.png'
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import { useVerifyTokenQuery } from '../../features/managingRequestToken/managingRequestTokenApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'

const CreateManagerForm = () => {

    const navigate = useNavigate()
    const { email, tokenId } = useParams()

    const { data: tokenData, isLoading, isSuccess, isError } = useVerifyTokenQuery({email, tokenId})

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confitmPassword, setConfitmPassword] = useState('')
    const [managerEmail, setManagerEmail] = useState('')
    const [message, setMessage] = useState('')
    const [clubName, setClubName] = useState('')
    const [address, setAddress] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    useEffect(() => {
        if(tokenData){
            console.log(tokenData.token._id)
        }
    }, [tokenData])

    const sendRequest = async() => {
        console.log('fwef')
    }

    let content

    if(isLoading) content = <PulseLoader />
    if(isSuccess) content = (
        <><h3>Create your Managing Account</h3>
            <input
                className='input-field'
                placeholder='Username'
                type='text'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <input
                className='input-field'
                placeholder='Password'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <input
                className='input-field'
                placeholder='Confirm password'
                type='password'
                value={confitmPassword}
                onChange={(e) => setConfitmPassword(e.target.value)}
            />
            <input
                className='input-field'
                placeholder='Email'
                type='text'
                value={managerEmail}
                onChange={(e) => setManagerEmail(e.target.value)}
            />
            <input
                className='input-field'
                placeholder='Club Name'
                type='text'
                value={clubName}
                onChange={(e) => setClubName(e.target.value)}
            />
            <input
                className='input-field'
                placeholder='Address'
                type='text'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
            />
            <button onClick={sendRequest} className='submit-button'>Send</button>
            {error && <p className='errmsg'>{error}</p>}
            {success && <p className='successmsg'>{success}</p>}</>
    )
    if(isError) content = <h2>404 Not Found</h2>

    return (
        <div className='form-container'>
            <div className='logo-container'>
                <img
                    src={logo}
                    style={{cursor: 'pointer'}}
                    alt='CamNextDoor'
                    onClick={() => navigate('/')}
                />
            </div>
            {content}
        </div>
    )
}

export default CreateManagerForm