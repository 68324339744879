import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContentSectionCSS from '../ContentSection.module.css';
import React, { useRef } from 'react';
import { formatDate } from '../../../common/utils';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {selectPremiumPostById, useDeletePremiumPostMutation} from '../../../features/premiumPosts/premiumPostsApiSlice';
import {useUpdateRemoveUserPremiumContentMutation} from "../../../features/user/usersApiSlice";
import fireIcon from '../../../assets/img/fire-icon.png'

const SinglePremiumPostView = ({ loggedUser }) => {
    const { premiumPostId } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const videoRef = useRef();
    const [deletePremiumPost, { isLoading }] = useUpdateRemoveUserPremiumContentMutation()

    const post = useSelector((state) => selectPremiumPostById(state, premiumPostId));

    const handleEdit = () => {
        navigate(`/dash/user/content-section/premium-posts/${premiumPostId}/edit`, { state: { from: state.from } });

    };

    const handleDelete = async () => {

        await deletePremiumPost({ id: loggedUser._id, premiumPostId: premiumPostId }).unwrap()
        navigate(state.from)
    };

    let content;

    content = (
        <div className={ContentSectionCSS['single-post']}>
            <div className={`${ContentSectionCSS['header']} ${ContentSectionCSS['single-post-header']}`}>
                <ArrowBackIosRoundedIcon onClick={() => navigate(state.from)}/>
                <img src={loggedUser?.profileImage} alt="user"/>
                <p>{loggedUser?.username}</p>
                <div className={ContentSectionCSS['post-actions']}>
                    <EditIcon onClick={handleEdit}/>
                    <DeleteIcon onClick={handleDelete}/>
                </div>
            </div>
            <div className={ContentSectionCSS['media-container']}>
                {post.type === 'image' ?
                    <img src={post.url} alt='gallery-placeholder'/>
                    : (
                        <video ref={videoRef}>
                            <source src={post.url}/>
                            Your browser does not support the video tag.
                        </video>
                    )}
            </div>
            <div className={ContentSectionCSS['text-content']}>
                <div>
                    <h3>{post.title}</h3>
                    <p>{post?.description ? post.description : '.'}</p>
                    <span>{formatDate(post?.createdAt)}</span>
                    <h5>Price</h5>
                    <p>{`${post?.price} CHF`}</p>
                </div>
                <div className={ContentSectionCSS['like-button']}>
                    <button className="blur-button blur-button-post">
                        <img src={fireIcon} alt="Custom Fire Icon"/>
                        <span>12</span>
                    </button>
                </div>
            </div>
        </div>
    );

    return content;
};

export default SinglePremiumPostView;
