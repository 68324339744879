import {Outlet, useLocation} from 'react-router-dom'
import DesktopDetected from './DesktopDetected'
import { DESKTOP_SCREEN_DETECTED } from '../config/consts'
import {useEffect, useState} from 'react'
import useTitle from '../hooks/useTitle'
import AgeConfirmation from './AgeConfirmation/AgeConfirmation'

const VERIFICATION_REGEX = /^\/users\/([a-fA-F0-9]{24})\/(?:verify-profile-image|verify-documents|verify)\/([a-fA-F0-9]{64})(?:\/(accept|decline))?$/;

const Layout = () => {

    useTitle('CamNextDoor')

    const { pathname } = useLocation()

    const [isDesktopSize, setIsDesktopSize] = useState(window.innerWidth > DESKTOP_SCREEN_DETECTED)
    const [orientation, setOrientation] = useState(window.screen.orientation?.type.startsWith('portrait') ? 'portrait' : 'landscape')
    const [match, setMatch] = useState(false)
    const isEighteen = localStorage.getItem('isEighteen')

    useEffect(() => {

        setMatch(VERIFICATION_REGEX.test(pathname))
        const handleResize = () => {
            setIsDesktopSize(window.innerWidth > DESKTOP_SCREEN_DETECTED)
        }

        const handleOrientationChange = () => {
            setOrientation(window.screen.orientation?.type.startsWith('portrait') ? 'portrait' : 'landscape')
        }

        window.addEventListener('resize', handleResize)
        window.addEventListener('orientationchange', handleOrientationChange)

        handleResize()
        handleOrientationChange()

        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('orientationchange', handleOrientationChange)
        }
    }, [pathname])

    let content

    if ((isDesktopSize || orientation !== 'portrait') && !match) {
        content = <DesktopDetected />
    }
    else if ((!isEighteen || isEighteen === 'false') && pathname !== '/' && pathname !== '/dash/login' && pathname !== '/dash/signup' && !VERIFICATION_REGEX.test(pathname)){
        content = <AgeConfirmation />
    }
    else{
        content = <Outlet />
    }

    return content
}
export default Layout