import ContentSectionCSS from './ContentSection.module.css'
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined'
import GridOnIcon from '@mui/icons-material/GridOn'
import {useEffect, useState} from 'react'
import SubscriptionFeed from './SubscriptionFeed'
import GridPost from './GridPost'
import {
    useAddNewSubscriptionMutation,
    useCheckSubscriptionMutation
} from "../../../features/subscription/subscriptionApiSlice";
const Subscription = ({paramUser,loggedUser}) => {

    const [isFeed, setIsFeed] = useState(false)
    const [isImage, setIsImage] = useState(false)
    const [isVideo, setIsVideo] = useState(false)
    const [posts, setPosts] = useState([])
    const [isSubscribed, setIsSubscribed] = useState(false)
    const [checkSubscription] = useCheckSubscriptionMutation();
    const [addSubscription] = useAddNewSubscriptionMutation();

    useEffect(() => {
        if(paramUser) setPosts(paramUser.posts)
        console.log("mikac")
        const checkSubscriptionStatus = async () => {
            try {
                const result = await checkSubscription({
                    userId: loggedUser?._id,
                    paramUserId: paramUser?._id
                }).unwrap();
                setIsSubscribed(result.isSubscribed);
            } catch (error) {
                console.error('Error checking subscription status:', error);
            }
        };

        checkSubscriptionStatus();
    }, [paramUser,loggedUser])

    const handleVideoFilter = () => {
        if(!isVideo){
            setIsVideo(true)
            setIsImage(false)
        } else setIsVideo(false)
    }

    const handleImageFilter = () => {
        if(!isImage){
            setIsImage(true)
            setIsVideo(false)
        } else setIsImage(false)
    }

    let content;

    const handleSubscription = async (duration) => {
        try {
            // Pozovi mutaciju za dodavanje pretplate
            const result = await addSubscription({
                subscriberId: loggedUser._id,
                subscribedToId: paramUser._id,
                durationInMonths: duration
            }).unwrap();

            setIsSubscribed(true)
            console.log(result.message);
        } catch (error) {
            console.error('Error adding subscription:', error);
        }
    };

    const handleRemoveSubscription = async () => {
        try {
            const result = await addSubscription({
                subscriberId: loggedUser._id,
                subscribedToId: paramUser._id,
                durationInMonths: 1
            }).unwrap()
            setIsSubscribed(false)
        } catch (error) {
            console.error('Error adding subscription:', error)
        }
    }

    if (!isSubscribed) {
        // Ako korisnik nije pretplaćen, prikaži prompt za pretplatu
        content = (
            <div className={ContentSectionCSS['subscription-container']}>
                <h2>Subscribe: {paramUser?.username}</h2>
                <p>120 Videos | 230 Pictures</p>
                <button onClick={() => handleSubscription(1)} className='default-button'>
                    1 Month - 19$
                </button>
                <button onClick={() => handleSubscription(3)} className='default-button'>
                    3 Months - 49$
                </button>
                <button onClick={() => handleSubscription(12)} className='default-button'>
                    12 Months - 199$
                </button>
            </div>
        );
    } else {
        // Ako je korisnik pretplaćen, prikaži sadržaj
        const galleryContent = () => {
            const images = [];
            const totalSlots = 12; // 4 rows * 3 columns
            const postCount = posts?.length || 0;

            for (let i = postCount - 1; i >= 0; i--) {
                images.push(<GridPost key={postCount - i} postId={posts[i]} isImage={isImage} isVideo={isVideo} />);
            }

            // Add empty slots if there are less than 12 posts
            if (postCount < totalSlots) {
                for (let i = 0; i < totalSlots - postCount; i++) {
                    images.push(<div key={`empty-${i}`} className={ContentSectionCSS['empty-slot']} />);
                }
            }

            return images;
        };

        content = (
            <div className={ContentSectionCSS['gallery-container']}>
                <div className={ContentSectionCSS['gallery-header']}>
                    <div>
                        <SmartDisplayOutlinedIcon onClick={handleVideoFilter} className={`${isVideo ? ContentSectionCSS['active'] : ''}`} />
                        <ImageOutlinedIcon onClick={handleImageFilter} className={`${isImage ? ContentSectionCSS['active'] : ''}`} />
                        <div>
                            <span>120 videos</span>
                            <span>230 pictures</span>
                        </div>
                    </div>
                    <div>
                        {isSubscribed && <button className='invert-button' onClick={handleRemoveSubscription}>Unsubscribe</button> }
                        <SquareOutlinedIcon className={`${isFeed ? ContentSectionCSS['active'] : ''}`}
                                            onClick={() => setIsFeed(true)} />
                        <GridOnIcon className={`${!isFeed ? ContentSectionCSS['active'] : ''}`}
                                    onClick={() => setIsFeed(false)} />
                    </div>
                </div>
                {isFeed ?
                    <SubscriptionFeed posts={posts} isVideo={isVideo} isImage={isImage} />
                    :
                    <div className={ContentSectionCSS['gallery-grid']}>
                        {galleryContent()}
                    </div>
                }
            </div>
        );
    }

    return content;
}

export default Subscription