import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {selectPostById} from '../../../features/post/postsApiSlice'
import ContentSectionCSS from './ContentSection.module.css'
import React, {useEffect, useRef, useState} from 'react'
import {formatDate} from '../../../common/utils'
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import {selectUserById} from '../../../features/user/usersApiSlice'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import FullScreenModal from '../FullScreenModal'
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded'

const SinglePostView = () => {

    const { id, postId } = useParams()
    const { state } = useLocation()
    const navigate = useNavigate()
    const videoRef = useRef()
    const [showPlayIcon, setShowPlayIcon] = useState(true)
    const [showPauseIcon, setShowPauseIcon] = useState(false)

    const handleVideoPlay = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play()
                setShowPlayIcon(true)
                setTimeout(() => setShowPlayIcon(false), 300) // Hide after 300ms
            } else {
                videoRef.current.pause()
                setShowPauseIcon(true)
                setTimeout(() => setShowPauseIcon(false), 300) // Hide after 300ms
            }
        }
    }

    const post = useSelector(state => selectPostById(state, postId))
    const user = useSelector(state => selectUserById(state, id))

    let content

    content = (
        <div className={`${ContentSectionCSS['single-post']} ${ContentSectionCSS['single-post-original']}`}>
            <div className={`${ContentSectionCSS['header']} ${ContentSectionCSS['single-post-header']}`}>
                <ArrowBackIosRoundedIcon onClick={() => navigate(state.from)}/>
                <img src={user?.profileImage} alt="user"/>
                <p>{user?.username}</p>
            </div>
            <div className={ContentSectionCSS['media-container']}>
                {post.type === 'image' ?
                    <img src={post.url} alt='gallery-placeholder'/>
                    : (
                        <video ref={videoRef} onClick={handleVideoPlay}>
                            <source src={post.url}/>
                            Your browser does not support the video tag.
                        </video>
                    )}
                {post?.type !== 'image' &&
                    <PlayArrowRoundedIcon
                        className={`${ContentSectionCSS['play-icon']} ${showPlayIcon ? ContentSectionCSS['show'] : ContentSectionCSS['hide']}`}
                    />}
                {post?.type !== 'image' &&
                    <PauseRoundedIcon
                        className={`${ContentSectionCSS['pause-icon']} ${showPauseIcon ? ContentSectionCSS['show'] : ContentSectionCSS['hide']}`}
                    />
                }
            </div>
            <div className={ContentSectionCSS['text-content']}>
                <div>
                    <p>{post?.description ? post.description : '.'}</p>
                    <span>{formatDate(post?.createdAt)}</span>
                </div>
                <div>
                    <LocalFireDepartmentOutlinedIcon/>
                    <span>{post?.likes?.length}</span>
                </div>
            </div>
        </div>
    )

    return content
}

export default SinglePostView