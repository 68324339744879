import React, {useEffect, useState} from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import CameraRecording from '../CameraRecording/CameraRecording'
import MyStories from './MyStories'
import DashNavTop from './DashNavTop'

const MyStoriesParent = ({loggedUser}) => {

    const [showLoader, setShowLoader] = useState(true)
    const [videoSrc, setVideoSrc] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)
    const [currentStories, setCurrentStories] = useState([])

    useEffect(() => {
        if(loggedUser) {
            setCurrentStories(loggedUser.stories)
            setShowLoader(false)
        }
    }, [loggedUser])

    let content

    if(showLoader){content = <PulseLoader color={'#F92E85FF'} className='pulse-loader'/>
    } else if(videoSrc) {
        content =
            <CameraRecording
                loggedUser={loggedUser}
                selectedFile={selectedFile}
                videoSrc={videoSrc}
                setVideoSrc={setVideoSrc}
            />
    } else {
            content =
                <DashNavTop
                    loggedUser={loggedUser}
                    setVideoSrc={setVideoSrc}
                    setSelectedFile={setSelectedFile}
                    currentStories={currentStories}
                />
    }

    return content
}

export default MyStoriesParent