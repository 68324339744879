import UserVerificationCSS from './UserVerification.module.css'
import useAuth from '../../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGear, faPen, faXmark} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useGetDocumentsByUserIdMutation } from "../../features/documentsToken/doucmentsTokenApiSlice"

const UserVerification = ({ loggedUser }) => {
    const { email } = useAuth() // Assuming useAuth provides userId
    const navigate = useNavigate()

    const editedUsername = email?.split('@')[0]
    const [getDocumentsByUserId, { isSuccess, isLoading, isError, data: documents }] = useGetDocumentsByUserIdMutation()
    const [comment, setComment] = useState("")
    const [waitingForResponse, setWaitingForResponse] = useState(true) // Default to waiting for response

    useEffect(() => {
        if (loggedUser?.verified) {
            navigate('/dash/user/my-stories')
        } else {
            const fetchDocuments = async () => {
                try {
                    const result = await getDocumentsByUserId({ userId: loggedUser._id })
                    console.log(result)
                    if (result?.data) {
                        const userDocument = result.data[0] // Assuming the first document is the relevant one
                        if (userDocument.comment) {
                            setComment(userDocument.comment)
                            setWaitingForResponse(false)
                        } else {
                            setWaitingForResponse(true)
                        }
                    } else {
                        setWaitingForResponse(false)
                    }
                } catch (error) {
                    console.error('Failed to fetch documents', error)
                    setWaitingForResponse(false)
                }
            }

            fetchDocuments()
        }
    }, [loggedUser, getDocumentsByUserId, navigate])

    useEffect(() => {
        console.log(waitingForResponse)
    }, [waitingForResponse])

    return (
        <div className={`component-container ${UserVerificationCSS['verify-container']}`}>
            {waitingForResponse && !comment ?
                <div className={UserVerificationCSS['main-section']}>
                    <h1>Verification Pending...</h1>
                    <p>Thank you! We are checking your data within the next 24 hours. <br />You will be informed by email as soon as your profile gets approved!</p>
                </div>
                :
                <div className={UserVerificationCSS['main-section']}>
                    <h1>Hey <b>{editedUsername}</b></h1>
                    {comment ? (
                        <>
                            <p>Please click on "Verify my Profile" to verify it again.</p>
                            <button onClick={() => navigate('/dash/documents', {state: {from: '/dash/verify'}})}>Verify
                                my Profile
                            </button>
                            <div className={UserVerificationCSS['comment-section']}>
                                <FontAwesomeIcon icon={faXmark} />
                                <p>{comment}</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>To show your profile on CamNextDoor we need to verify you! <br /> Please click on "Verify my Profile"
                                to continue.</p>
                            <button onClick={() => navigate('/dash/documents', { state: { from: '/dash/verify' } })}>Verify my Profile</button>
                        </>
                    )}
                </div>
            }
            <div className={UserVerificationCSS['button-section']}>
                <button onClick={() => navigate('/dash/user/settings', { state: { from: '/dash/verify' } })}><FontAwesomeIcon icon={faGear} /></button>
                <button onClick={() => navigate('/dash/user/edit', { state: { from: '/dash/verify' } })}><FontAwesomeIcon icon={faPen} /></button>
            </div>
        </div>
    )
}

export default UserVerification
