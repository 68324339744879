import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import StayPrimaryPortraitRoundedIcon from '@mui/icons-material/StayPrimaryPortraitRounded'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {createTheme, ThemeProvider} from '@mui/material/styles'
import MyStories from './MyStories'
import BroadcasterSettings from '../BroadcasterSettings/BroadcasterSettings'
import ClicksAndImpressions from '../ClicksAndImpressions/ClicksAndImpressions'
import EditUserProfile from '../../features/user/EditUserProfile'
import SwipeableViews from 'react-swipeable-views'

const theme = createTheme({
    palette: {
        primary: {
            main: '#000',
        },
    },
})

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            style={{ minHeight: 'calc(100vh - 120px)' }}
        >
            {value === index && <Box sx={{paddingBottom: '64px'}}>{children}</Box>}
        </div>
    )
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function DashNavTop({ loggedUser, currentStories, setSelectedFile, setVideoSrc }) {

    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleChangeIndex = (index) => {
        setValue(index)
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: '#ffffff' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="fullWidth"
                        centered
                    >
                        <Tab sx={{ p: 2 }} icon={<StayPrimaryPortraitRoundedIcon/>} {...a11yProps(0)} />
                        <Tab sx={{ p: 2 }} icon={<MenuIcon/>} {...a11yProps(1)} />
                        <Tab sx={{ p: 2 }} icon={<CreditCardOutlinedIcon/>} {...a11yProps(2)} />
                        <Tab sx={{ p: 2 }} icon={<SettingsOutlinedIcon/>} {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <CustomTabPanel value={value} index={0}>
                        <MyStories
                            loggedUser={loggedUser}
                            setVideoSrc={setVideoSrc}
                            setSelectedFile={setSelectedFile}
                            currentStories={currentStories}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <EditUserProfile loggedUser={loggedUser}/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <ClicksAndImpressions loggedUser={loggedUser} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <BroadcasterSettings userId={loggedUser?._id} />
                    </CustomTabPanel>
                </SwipeableViews>
            </Box>
        </ThemeProvider>
    )
}