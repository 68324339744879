import Modal from 'react-modal'
import GallerySectionCSS from './GallerySection.module.css'

Modal.setAppElement('#root')

const FullScreenModal = ({fullscreenOpened, closeModal, postUrl, postType}) => {

    return(
        <Modal
            isOpen={fullscreenOpened}
            onRequestClose={closeModal}
            className={
                {
                    base: `modal-content ${GallerySectionCSS['fullscreen-modal-content']}`,
                    afterOpen: 'modal-content--after-open',
                    beforeClose: 'modal-content--before-close'
                }
            }
            overlayClassName={
                {
                    base: 'modal-overlay',
                    afterOpen: `modal-overlay--after-open ${GallerySectionCSS['fullscreen-modal-overlay--after-open']}`,
                    beforeClose: 'modal-overlay--before-close'
                }
            }
            closeTimeoutMS={300}
        >
            <div className={`modal-close ${GallerySectionCSS['fullscreen-modal-close']}`}>
                <button
                    onClick={closeModal}
                    className='close-button'
                >
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                        <path
                            d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41z'/>
                    </svg>
                </button>
            </div>
            <div className={GallerySectionCSS['fullscreen-container']}>
                {postType === 'image' ?
                    <img src={postUrl} alt='gallery-placeholder'/>
                    : (
                        <video controls>
                            <source src={postUrl}/>
                            Your browser does not support the video tag.
                        </video>
                    )}
            </div>
        </Modal>
    )
}

export default FullScreenModal