import { Outlet } from 'react-router-dom'
import DashNav from './DashNav'

const DashLayout = ({email, loggedUser}) => {
    const isEighteen = localStorage.getItem('isEighteen')
    return (
        <>
                <Outlet />
            {isEighteen === 'true' && <DashNav email={email} loggedUser={loggedUser}/>}
        </>
    )
}
export default DashLayout