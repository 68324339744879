import { useSelector } from 'react-redux';
import ContentSectionCSS from '../Subscription/ContentSection.module.css'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectPremiumPostById } from '../../../features/premiumPosts/premiumPostsApiSlice';
import { useCreatePurchaseMutation, useCheckPurchaseStatusQuery } from '../../../features/premiumPosts/premiumPostsApiSlice';

const GridPremiumPost = ({ loggedUser,postId, isVideo, isImage }) => {
    const videoRef = useRef();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [duration, setDuration] = useState(0);
    const [isPurchased, setIsPurchased] = useState(false);

    const [purchasePremiumPost] = useCreatePurchaseMutation();
    const userId = loggedUser?._id
    console.log("mikac")
    const { data: purchaseStatus } = useCheckPurchaseStatusQuery({ userId, premiumPostId: postId });
    console.log(purchaseStatus)
    useEffect(() => {
        if (purchaseStatus?.isPurchased) {
            setIsPurchased(true);
        }
    }, [purchaseStatus]);

    useEffect(() => {
        const handleLoadedMetadata = () => {
            if (videoRef?.current) {
                setDuration(Math.round(videoRef.current.duration));
            }
        };

        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
        }

        return () => {
            if (videoElement) {
                videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
            }
        };
    }, []);

    const handlePurchase = async () => {
        try {
            await purchasePremiumPost({ userId: userId, premiumPostId: postId }).unwrap();
            setIsPurchased(true);
        } catch (error) {
            console.error('Error during purchase:', error);
        }
    };

    const post = useSelector((state) => selectPremiumPostById(state, postId));


    if (post) {
        if (isPurchased || post.isPurchased) {
            if (post.type === 'image' && !isVideo) {
                return (
                    <div className={ContentSectionCSS['gallery-item']} onClick={() => navigate(`premium-posts/${postId}`, { state: { from: pathname } })}>
                        <img src={post.url} alt='gallery-placeholder' />
                    </div>
                );
            } else if (post.type === 'video' && !isImage) {
                return (
                    <div className={ContentSectionCSS['gallery-item']} onClick={() => navigate(`premium-posts/${postId}`, { state: { from: pathname } })}>
                        <video ref={videoRef}>
                            <source src={post.url} />
                            Your browser does not support the video tag.
                        </video>
                        <PlayArrowRoundedIcon className={ContentSectionCSS['play-button']} onClick={() => navigate(`${postId}`)} />
                        <span>{duration} sec</span>
                    </div>
                );
            }
        } else {
            return (
                <div className={ContentSectionCSS['gallery-item']}>
                    <img src={post.blurredUrl} alt='blurred-content' className={ContentSectionCSS['blurred-image']}/>
                    <div className={ContentSectionCSS['post-blur']}>
                        <h2>{post.title || (post.type === 'image' ? "Unlock this image" : "Unlock this video")}</h2>
                        <p>{post.type === 'image' ? "Image" : "Video"} | {duration > 0 ? `${duration} sec` : ""}</p>
                        <button onClick={handlePurchase} className={ContentSectionCSS['buy-button']}>
                            Access now: ${post.price || 'Price'}
                        </button>
                    </div>
                </div>
            );
        }
    } else return null;
};

export default GridPremiumPost;
