import { store } from '../../app/store'
import {usersApiSlice} from '../user/usersApiSlice'
import {storiesApiSlice} from '../story/storiesApiSlice'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import {postsApiSlice} from '../post/postsApiSlice'
import {premiumPostsApiSlice} from '../premiumPosts/premiumPostsApiSlice'

const Prefetch = () => {
    useEffect(() => {
        const users = store.dispatch(usersApiSlice.endpoints.getUsers.initiate())
        const stories = store.dispatch(storiesApiSlice.endpoints.getStories.initiate())
        const posts = store.dispatch(postsApiSlice.endpoints.getPosts.initiate())
        const premiumPosts = store.dispatch(premiumPostsApiSlice.endpoints.getPremiumPosts.initiate())

        return () => {
            users.unsubscribe()
            stories.unsubscribe()
            posts.unsubscribe()
            premiumPosts.unsubscribe()
        }
    }, [])

    return <Outlet />
}
export default Prefetch