import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import AppsIcon from '@mui/icons-material/Apps'
import DiamondIcon from '@mui/icons-material/Diamond'
import FavoriteIcon from '@mui/icons-material/Favorite'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import MyFollowers from '../BroadcasterSettings/MyFollowers'
import Subscription from './Subscription/Subscription'
import useAuth from '../../hooks/useAuth'
import PremiumContent from './PremiumContent/PremiumContent'
import {useEffect} from 'react'
import SwipeableViews from 'react-swipeable-views'

const theme = createTheme({
    palette: {
        primary: {
            main: '#000',
        },
    },
})

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            style={{ minHeight: 'calc(100vh - 120px)' }}
        >
            {value === index && <Box sx={{paddingBottom: '64px'}}>{children}</Box>}
        </div>
    )
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function Navigation({ loggedUser }) {
    const [value, setValue] = React.useState(0)
    const { isProvider } = useAuth()

    useEffect(() => {
        const savedTab = localStorage.getItem('activeTab')
        if (savedTab !== null) {
            setValue(parseInt(savedTab, 10))
        }
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        localStorage.setItem('activeTab', newValue)
    }

    const handleChangeIndex = (index) => {
        setValue(index)
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'sticky', top: '0', backgroundColor: '#ffffff', zIndex: '999' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="fullWidth"
                        centered
                    >
                        <Tab sx={{ p: 2 }} icon={<FavoriteIcon/>} {...a11yProps(0)} />
                        <Tab sx={{ p: 2 }} icon={<AppsIcon/>} {...a11yProps(1)} />
                        <Tab sx={{ p: 2 }} icon={<DiamondIcon/>} {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <CustomTabPanel value={value} index={0}>
                        <MyFollowers userId={loggedUser?._id}/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Subscription loggedUser={loggedUser}/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <PremiumContent loggedUser={loggedUser} />
                    </CustomTabPanel>
                </SwipeableViews>
            </Box>
        </ThemeProvider>
    )
}